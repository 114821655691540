.content {
  /* // [lead] text goes here [/lead] */
  .lead * {
    max-width: 45ch;
    @apply font-serif text-3xl font-normal;

    .dropcap {
      font-size: 2.9em;
    }
  }
  /* // [pullquote cite="" url=""] ... [/pullquote] */
  .pullquote {
    @apply mt-28 ml-2 mb-3 pt-28 float-right border-t-2 border-black border-solid font-sans text-md font-extrabold leading-8;

    cite {
      @apply mt-28 block text-right font-light italic font-sans text-sm;
    }
  }

  /* promo type="free-text"] text goes here [/promo] */
  .promo {
    @apply mt-3 ml-6 p-3 pb-6 space-y-3 border rail-border bg-white dark:bg-zinc-600 font-sans text-md  text-zinc-800 dark:text-slate-100 float-right clear-both;
    text-transform: none;
    strong {
      @apply font-medium;
    }

    h6 {
      @apply text-sm uppercase;
    }
  }

  .poetry {
    @apply mb-8 border-[1px] border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-700 text-lg rounded-sm overflow-x-scroll;
    .poem {
      @apply p-6 w-[62.1rem];
    }
    p {
      @apply max-w-full text-nowrap;
    }
  }
  .poetry-indented {
    p {
      @apply ml-7 -indent-7;
    }
  }

  /* // [center] text goes here [/center] */
  .text-align-center > * {
    @apply px-4 text-center;
  }

  /* // [right] text goes here [/right] */
  .text-align-right > * {
    @apply pl-4 text-right;
  }
}
