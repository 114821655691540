// disable WebKit’s proprietary pseudo elements for search input
// See: https://stackoverflow.com/questions/18856246/input-type-search-hide-the-icons
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

strong,
b {
  @apply font-bold;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.rail-border {
  @apply border-zinc-600 dark:border-slate-300 border-dotted;
}
.rail-border-solid {
  @apply rail-border !border-solid;
}
.rail-divide {
  @apply divide-zinc-600 dark:divide-slate-300 divide-dotted;
}
.rail-divide-solid {
  @apply rail-divide !border-solid;
}

.paper {
  @apply relative;
}

// =================================
// Underlines

a {
  @apply no-underline;
  &:hover {
    @apply underline decoration-1 decoration-dotted underline-offset-4;
  }
}
a:hover .media figcaption {
  text-decoration: none !important;
}

// .article-container {
//   transition:
//     transform 0.1s ease,
//     opacity 0.1s ease;
// }

// .article-card {
//   flex: 1 0 100%;
//   transition:
//     transform 0.5s ease,
//     opacity 0.5s ease;
// }

// /* Initial positioning */
// .article-enter-left .article-card,
// .article-enter-right .article-card {
//   opacity: 0; /* Start with opacity 0 */
//   transform: translateY(-20px); /* Slightly above */
// }

// /* Active state (centered) */
// .article-active .article-card {
//   opacity: 1;
//   transform: translateY(0); /* Centered in view */
// }

// /* Exiting states */
// .article-exit-left .article-card,
// .article-exit-right .article-card {
//   opacity: 0; /* Fade out */
//   // transform: translateY(20px); /* Slightly below */
// }

// /* Fade and entrance animation */
// .enter-animation {
//   opacity: 1;
//   transform: translateY(0%);
// }

// /* Overall opacity and transition control */
// .article-exit {
//   opacity: 0;
// }

// .article-enter {
//   opacity: 0;
//   // transform: translateY(-20px); /* Slightly above */
//   transition:
//     opacity 0.1s ease,
//     transform 0.1s ease;
// }

// .article-enter-active {
//   opacity: 1;
//   // transform: translateY(0);
//   transition:
//     opacity 0.1s ease,
//     transform 0.1s ease;
// }

// .article-exit-active {
//   opacity: 0;
//   // transform: translateY(20px); /* Slightly below */
//   transition:
//     opacity 0.1s ease,
//     transform 0.1s ease;
// }
