.content {
  @apply text-xl tablet-lg:text-2xl font-normal font-serif break-words;

  h1 {
    @apply hidden;
  }
  h2 {
    @apply text-3xl font-medium font-sans;
  }
  h3 {
    @apply text-2xl font-medium font-sans;
  }
  h4 {
    @apply text-xl font-medium font-sans;
  }
  h5 {
    @apply text-lg font-thin font-sans;
  }
  h6 {
    @apply text-sm font-thin uppercase font-sans;
  }

  a {
    @apply underline decoration-2 decoration-solid decoration-blue-600 underline-offset-4;
    &:hover {
      @apply underline decoration-2 underline-offset-4;
    }
  }

  p,
  blockquote,
  li,
  pre {
    max-width: 86ex;
    @apply mb-6 tablet:mb-6;
  }
  p:last-child {
    @apply mb-0;
  }

  strong,
  b {
    @apply font-medium;
  }

  div > p:last-child {
    @apply mb-0;
  }

  ul,
  ol {
    @apply pl-6 mb-3;
  }

  li {
    list-style-type: disc;
  }
  ol li {
    list-style-type: number;
  }

  /* // [blockquote cite="name" url=""]...[/blockquote] */
  blockquote {
    @apply ml-0 pl-5 pr-1 tablet:pl-6 tablet:pr-3;
    cite {
      @apply mt-1 mr-3 block text-right italic;
    }
  }

  /* [rule] */
  hr {
    margin: 2em 0;
    border: 0;
    border-top: 1px #555 solid;
    clear: both;
  }
  hr.sm {
    margin-left: auto;
    margin-right: auto;
    width: 25%;
  }
  hr.md {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  hr.lg {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
  hr.xl {
    width: 100%;
  }

  pre {
    @apply whitespace-pre-wrap break-words p-2 max-w-2xl rounded-sm bg-slate-200 font-mono font-bold text-sm text-red-600;
    code {
      @apply p-0 bg-transparent;
    }
  }

  code {
    @apply py-0.5 px-1 bg-slate-200 text-red-600 font-mono font-bold text-sm rounded-sm;
  }

  .media {
    @apply clear-both;
    .frame {
      @apply m-0 w-full;
    }
    figcaption,
    figcaption p {
      @apply max-w-[100ex];
    }
  }
}

.endnote {
  @apply my-8;
  @apply font-normal font-sans text-sm break-words;

  a {
    @apply underline decoration-1;
  }

  li {
    @apply mb-2 tablet:mb-3;
  }
  .line {
    @apply mb-6 block w-20;
    @apply border-b-[1px] border-gray-500 border-solid;
  }
}

.content-page {
  @apply text-xl;
}

.paper-event .content {
  @extend .content;
  @apply text-lg tablet-lg:text-xl font-normal font-sans;
  p,
  blockquote,
  li,
  pre {
    @apply max-w-none;
  }
}

// ==========================================================================
// Contributors

.content-contributors {
  a {
    @apply decoration-zinc-800;
  }
}

// IMAGES

figcaption,
figcaption p {
  @apply no-underline hover:no-underline;
  @apply max-w-[100ex];
  @apply w-full;
  @apply pt-1.5 px-0.5 text-xs tablet-lg:text-sm font-sans font-normal text-zinc-500 dark:text-zinc-300;
}

.media.width-xl,
.media.width-lg,
.media.width-md,
.media.width-sm {
  @apply pt-6 mb-12;
}

.media.width-sm {
  @apply ml-6;
}

.media.width-md {
  @apply tablet:ml-6;
}

.width-xl {
  @apply w-full;
}

.width-lg {
  @apply max-w-[66rem];
}
.width-md {
  @apply w-full tablet:max-w-[25rem] desktop:max-w-[33rem] tablet:float-right;
}
.width-sm {
  @apply mb-6 w-card desktop:w-card-lg float-right;
}
