html,
body {
  @apply text-base font-normal;
  @apply font-sans antialiased;
  @apply text-black;
}
//=========================================
// Theme Switcher
// Apply a subtile transition to all elements when switching themes
body * {
  @apply transition-colors duration-[50ms];
}

//=========================================
// Paper Styles
//=========================================

// Default theme
:root {
  --background-color: #f8fafc; /* slate-50 */
  --text-color: #18181b; /* zinc-900 */
  --navbar-background-color: #f8fafc; /* slate-50 */
  --navbar-text-color: #18181b; /* zinc-900 */
  --collection-background-color: #f8fafc; /* slate-50 */
  --collection-text-color: #18181b; /* zinc-900 */
  --logo-text-fill: gray; /* zinc-900 */
  --logo-path-fill: #18181b; /* zinc-900 */
  --subhead-fill: #18181b; /* zinc-900 */
  html,
  body {
    @apply bg-slate-100 tablet:bg-[#EF4444];
  }
  .pathfill {
    @apply fill-none;
    @media screen and (min-width: theme("screens.tablet")) {
      fill: var(--logo-path-fill);
    }
  }
  .textfill {
    @apply fill-red-500 tablet:fill-none;
    @media screen and (max-width: theme("screens.tablet")) {
      @apply fill-red-500;
    }
  }
  .subheadfill {
    fill: var(--subhead-fill);
  }
  .article-ad {
    @apply bg-zinc-100;
    @apply w-screen shadow-inner absolute left-0 right-0;
  }
}

.dark {
  --background-color: #27272a; /* zinc-800 */
  --text-color: #eef2ff; /* indigo-50 */
  --navbar-background-color: #27272a; /* zinc-800 */
  --navbar-text-color: #eef2ff; /* indigo-50 */
  --collection-background-color: #27272a; /* zinc-800 */
  --collection-text-color: #eef2ff; /* indigo-50 */
  --subhead-fill: #eef2ff; /* indigo-50 */
  html,
  body {
    @apply bg-indigo-800;
  }

  .pathfill {
    @apply fill-none tablet:fill-indigo-50;
  }
  .textfill {
    @apply fill-indigo-50 tablet:fill-none;
  }

  .subheadfill {
    fill: var(--subhead-fill);
  }
  .article-ad {
    @apply bg-zinc-700;
  }
}

// HOMEPAGE ==========================================
.theme-homepage {
  --logo-text-fill: #f8fafc; /* zinc-900 */
  --subhead-fill: #f8fafc; /* zinc-900 */
  .pathfill {
    // @apply fill-none tablet:fill-zinc-800;
    fill: none;
  }
  .textfill {
    fill: var(--logo-text-fill);
  }
  .subheadfill {
    fill: var(--subhead-fill);
  }
}
.dark .theme-homepage {
  --logo-path-fill: #f8fafc; /* zinc-900 */
}

// EVENTS ==========================================
.theme-events {
  --background-color: #eef2ff; /* indigo-50 */
  --text-color: #18181b; /* zinc-900 */
  --navbar-background-color: #eef2ff; /* indigo-50 */
  --navbar-text-color: #18181b; /* zinc-900 */
  --collection-background-color: #eef2ff; /* indigo-50 */
  --collection-text-color: #18181b; /* zinc-900 */
  .banner {
    .pathfill {
      @apply fill-none tablet:fill-indigo-950;
    }
    .textfill {
      @apply fill-indigo-950 tablet:fill-none;
    }
  }
}
.dark .theme-events {
  --background-color: #27272a; /* zinc-800 */
  --text-color: #eef2ff; /* indigo-50 */
  --navbar-background-color: #27272a; /* zinc-800 */
  --navbar-text-color: #eef2ff; /* indigo-50 */
  --collection-background-color: #27272a; /* zinc-800 */
  --collection-text-color: #eef2ff; /* indigo-50 */
  .banner {
    .pathfill {
      @apply fill-none tablet:fill-indigo-50;
    }
    .textfill {
      @apply fill-indigo-50 tablet:fill-none;
    }
  }
}

// CRITICS PAGE ==========================================
.theme-criticspage {
  --background-color: #fffbeb; /* amber-50 */
  --text-color: #18181b; /* zinc-900 */
  --navbar-background-color: #fffbeb; /* amber-50 */
  --navbar-text-color: #18181b; /* zinc-900 */
  --collection-background-color: #fffbeb; /* amber-50 */
  --collection-text-color: #18181b; /* zinc-900 */
  .banner {
    .pathfill {
      @apply fill-none tablet:fill-none;
    }
    .textfill {
      @apply fill-teal-300 tablet:fill-green-200;
    }
  }
  .article-ad {
    @apply bg-amber-100;
    @apply tablet-lg:w-auto tablet-lg:relative;
  }
}
.dark .theme-criticspage {
  --background-color: #2d2d2f; /* stone-800 */
  --text-color: #eef2ff; /* indigo-50 */
  --navbar-background-color: #2d2d2f; /* stone-800 */
  --navbar-text-color: #eef2ff; /* indigo-50 */
  --collection-background-color: #2d2d2f; /* stone-800 */
  --collection-text-color: #eef2ff; /* indigo-50 */
  .banner {
    .pathfill {
      @apply fill-none tablet:fill-indigo-50;
    }
    .textfill {
      @apply fill-indigo-50 tablet:fill-none;
    }
  }
  .article-ad {
    @apply bg-zinc-600;
  }
}

// TRIUBTES ==========================================
.theme-tributes {
  --background-color: #f1f5f9; /* slate-100 */
  --text-color: #18181b; /* zinc-900 */
  --navbar-background-color: #f1f5f9; /* slate-100 */
  --navbar-text-color: #18181b; /* zinc-900 */
  --collection-background-color: #f1f5f9; /* slate-100 */
  --collection-text-color: #18181b; /* zinc-900 */
}
.dark .theme-tributes {
  --background-color: #18181b; /* zinc-900 */
  --text-color: #eef2ff; /* indigo-50 */
  --navbar-background-color: #18181b; /* zinc-900 */
  --navbar-text-color: #eef2ff; /* indigo-50 */
  --collection-background-color: #18181b; /* zinc-900 */
  --collection-text-color: #eef2ff; /* indigo-50 */
}

// POETRY, FICTION, FIELD NOTES ==========================================
.theme-poetry,
.theme-fiction,
.theme-field-notes {
  --background-color: #f5f5f4; /* stone-100 */
  --text-color: #18181b; /* zinc-900 */
  --navbar-background-color: #f5f5f4; /* stone-100 */
  --navbar-text-color: #18181b; /* zinc-900 */
  --collection-background-color: #f5f5f4; /* stone-100 */
  --collection-text-color: #18181b; /* zinc-900 */
}
.dark .theme-poetry,
.dark .theme-fiction,
.dark .theme-field-notes {
  --background-color: #18181b; /* zinc-900 */
  --text-color: #eef2ff; /* indigo-50 */
  --navbar-background-color: #18181b; /* zinc-900 */
  --navbar-text-color: #eef2ff; /* indigo-50 */
  --collection-background-color: #18181b; /* zinc-900 */
  --collection-text-color: #eef2ff; /* indigo-50 */
}

// PEOPLE ==========================================
.theme-people {
  --background-color: #d1fae5; /* green-100 */
  --text-color: #18181b; /* zinc-900 */
  --navbar-background-color: #d1fae5; /* green-100 */
  --navbar-text-color: #18181b; /* zinc-900 */
  --collection-background-color: #d1fae5; /* green-100 */
  --collection-text-color: #18181b; /* zinc-900 */
}
.dark .theme-people {
  --background-color: #18181b; /* zinc-900 */
  --text-color: #eef2ff; /* indigo-50 */
  --navbar-background-color: #18181b; /* zinc-900 */
  --navbar-text-color: #eef2ff; /* indigo-50 */
  --collection-background-color: #18181b; /* zinc-900 */
  --collection-text-color: #eef2ff; /* indigo-50 */
}

// PAGE ==========================================
.theme-page {
  --background-color: #e0f7fa; /* cyan-50 */
  --text-color: #18181b; /* zinc-900 */
  --navbar-background-color: #e0f7fa; /* cyan-50 */
  --navbar-text-color: #18181b; /* zinc-900 */
  --collection-background-color: #e0f7fa; /* cyan-50 */
  --collection-text-color: #18181b; /* zinc-900 */
  .banner {
    .pathfill {
      @apply fill-none tablet:fill-indigo-950;
    }
    .textfill {
      @apply fill-cyan-900 tablet:fill-none;
    }
  }
}
.dark .theme-page {
  --background-color: #27272a; /* zinc-800 */
  --text-color: #eef2ff; /* indigo-50 */
  --navbar-background-color: #27272a; /* zinc-800 */
  --navbar-text-color: #eef2ff; /* indigo-50 */
  --collection-background-color: #27272a; /* zinc-800 */
  --collection-text-color: #eef2ff; /* indigo-50 */
  .banner {
    .pathfill {
      @apply fill-none tablet:fill-indigo-50;
    }
    .textfill {
      @apply fill-indigo-50 tablet:fill-none;
    }
  }
}

.theme {
  background-color: var(--background-color);
  color: var(--text-color);
}

.article-bar,
.navbar {
  background-color: var(--navbar-background-color);
  color: var(--navbar-text-color);
}

.navbar-fade {
  @apply bg-gradient-to-r from-transparent from-[1%];
  --tw-gradient-to-position: 15%;
  --tw-gradient-to: var(--navbar-background-color) var(--tw-gradient-to-position);
}

.collection {
  background-color: var(--collection-background-color);
  color: var(--collection-text-color);
}

.section-head {
  background-color: var(--collection-background-color);
  color: var(--collection-text-color);
}
